import React from 'react';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import api from '../../components/servicios/api';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import { withTranslation } from "react-i18next";
import Pageinfo from '../../components/PantallaCarga/Pageinfo';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import md5 from "md5";
import { isPlatform } from '@ionic/react';
import { Keyboard } from '@capacitor/keyboard';
import imagenPromo from '../../Imagenes/pareja.png';
import Roaming from '../../components/Herramientas/Roaming'
import Servicios from '../../components/Herramientas/Servicios';

let userConsumo;
let userpuk;
let userPromo = [];

class HerramientasTranslated extends React.Component {
    state = {
        loading: true,
        error: false,
        datos: [],
        sinDatos: false,
        modal: false,
        pin1: '',
        pin2: '',
        puk: '',
        puk2: '',
        sim: '',
        verificar: false,
        codigo: '',
        modalPromo: false,
        datosPromoNombre: '',
        datosPromoCodigo: '',
        verPromos: false,
        verPin: false,
        verRoaming: false,
        mostrarRoaming: false,
        sim2: false,
        pin1sim: '',
        pin2sim: '',
        puksim: '',
        puk2sim: '',
        simsim: '',
        servicios: false,
        modalServicios: false


    };
    componentDidMount() {
        if (isPlatform('ios') && !isPlatform('mobileweb')) {
            Keyboard.setAccessoryBarVisible({ isVisible: true });
        }
        const appConf = JSON.parse(localStorage.getItem('appconf'));
        if (appConf.mostrar_puk == '0') {
            this.setState({ verPin: false });
        } else {
            this.setState({ verPin: true });
        }

        if (appConf.activacion_servicios == '0') {
            this.setState({ servicios: false });
        } else {
            this.setState({ servicios: true });
        }

        const funciones1 = JSON.parse(localStorage.getItem('funciones'));
        const funciones = funciones1[0];
        if (funciones.roaming == 0) {
            this.setState({ verRoaming: false });
        } else {
            this.setState({ verRoaming: true });
        }



        userConsumo = [];
        userpuk = [];
        userPromo = [];
        this.fetchData();
        this.recuperaPromociones();
    }

    abrirModal = () => {
        const cliente = JSON.parse(localStorage.getItem('user'));
        if (cliente.password == md5(this.state.codigo.trim())) {
            this.setState({ modal: !this.state.modal });
        }
    }

    abrirModalServicios = () => {
        this.setState({ modalServicios: !this.state.modalServicios });

    }

    abrirModalPromo = (modal) => {
        if (modal == 'promo') {
            this.setState({ modalPromo: !this.state.modalPromo });
        } else {
            this.setState({ verPromos: false, verPin: false, modalRoaming: true, verRoaming: false });
        }

    }

    cerrarModal = () => {
        this.setState({ modal: !this.state.modal });
        window.location.reload();
    }

    cerrarModalPromo = () => {
        this.setState({ modalPromo: !this.state.modalPromo });
        window.location.reload();
    }

    cerrarModalservicios = () => {
        this.setState({ modalServicios: !this.state.modalServicios });
        window.location.reload();
    }

    cerrarModalRoaming = () => {
        this.setState({ modalRoaming: !this.state.modalRoaming });
        window.location.reload();
    }

    handleChange = async (e) => {
        if (e.target.name == 'codigo') {
            this.setState({ codigo: e.target.value });
            console.log(e.target.value)
        } else {
            this.obtenerPuk(e.target.value);
        }

    };



    fetchData = async () => {
        this.setState({ loading: true, error: null });

        try {
            const cliente = JSON.parse(localStorage.getItem('user'));
            await api.Clientes.telefonos(cliente.usuario).then(
                ({ data }) => (userConsumo = data)
            ).catch(function (err) {
                console.log(err);
            });

            if (!Array.isArray(userConsumo)) {
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false, datos: userConsumo });
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };

    recuperaPromociones = async () => {
        this.setState({ loading: true, error: null });

        try {
            const cliente = JSON.parse(localStorage.getItem('user'));
            await api.Clientes.consultarPromocion(cliente.usuario).then(
                ({ data }) => (userPromo = data)
            ).catch(function (err) {
                console.log(err);
            });

            if (!Array.isArray(userPromo)) {
                this.setState({ loading: false, verPromos: false });
            } else if (userPromo[0].codigo_promocion_app != '' && userPromo[0].codigo_promocion_app != null && userPromo[0].codigo_promocion_app != undefined) {
                this.setState({ loading: false, datosPromo: userPromo, verPromos: true, datosPromoCodigo: userPromo[0].codigo_promocion_app, datosPromoNombre: userPromo[0].nombre_promocion_app });
            } else {
                this.setState({ loading: false, verPromos: false });
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };

    obtenerPuk = async (ddi) => {
        this.setState({ sim2: false });

        try {
            await api.Clientes.puk(ddi).then(
                ({ data }) => (userpuk = data)
            ).catch(function (err) {
                console.log(err);
            });
            if (userpuk == '') {
                this.setState({ loading: false, pin2: '', puk: '', puk2: '', sim: '' });
            } else {

                //console.log(userpuk.sim1);
                if (typeof (userpuk.sim2) && userpuk.sim2 != undefined && userpuk.sim2 != false && userpuk.sim2 != '') {
                    //console.log(first)
                    this.setState({ sim2: true, pin1sim: userpuk.sim2.pin, pin2sim: userpuk.sim2.pin2, puksim: userpuk.sim2.puk, puk2sim: userpuk.sim2.puk2, simsim: userpuk.sim2.sim, pin1: userpuk.sim2.pin });
                }

                if (typeof (userpuk.sim1) && userpuk.sim1 != undefined && userpuk.sim1 != false && userpuk.sim1 != '') {
                    //console.log(first)
                    this.setState({ pin2: userpuk.sim1.pin2, puk: userpuk.sim1.puk, puk2: userpuk.sim1.puk2, sim: userpuk.sim1.sim, pin1: userpuk.sim1.pin });
                } else {
                    this.setState({ loading: false, pin2: userpuk.pin2, puk: userpuk.puk, puk2: userpuk.puk2, sim: userpuk.sim, pin1: userpuk.pin });
                }

            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }

    }

    comprobarRequisitos = () => {
        const appConf = JSON.parse(localStorage.getItem('appconf'));
        if (appConf.mostrar_puk == '2') {
            this.setState({ verificar: true });
        } else {
            this.setState({ modal: !this.state.modal });
        }

    }


    render() {
        const { t } = this.props;

        if (this.state.loading) {
            return <PageLoading />;
        }

        if (this.state.error) {
            return <PageError />;
        }
        if (this.state.sinDatos) {
            return <Pageinfo datos={'sinDDi'} />
        }

        return (
            <div className="container h-100 float-left" >
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="titulo-pagina">
                    <span className="">{t('Home.herramientas',)}</span>
                </div>
                {this.state.verPin && <div className="herramientas-list-sim">
                    <FontAwesomeIcon icon="sim-card" className="icon" />
                    <span className="sim-card-margin" onClick={this.comprobarRequisitos}>{t('Herramientas.puk',)}</span>
                </div>}
                <div className={this.state.verPromos ? "herramientas-list" : 'hidden'}>
                    <FontAwesomeIcon icon="tags" className="icon" />
                    <span className="" onClick={() => this.abrirModalPromo('promo')}>{t('Herramientas.promo',)}</span>
                </div>
                <div className={this.state.verificar ? "pass-sim" : 'hidden'}>
                    <span className="sim-info">{t('Herramientas.info',)}</span>
                    <form>
                        <input
                            onChange={this.handleChange}
                            className="form-input-sim"
                            type="password"
                            name="codigo"
                            placeholder={t('Herramientas.pass',)}
                        />
                        <div className="btn-aceptar enviar-pass-sim" onClick={this.abrirModal}>
                            <span className="" > {t('Herramientas.enviar',)}</span>
                        </div>

                    </form>
                </div>
                {this.state.verRoaming && <div className="herramientas-list">
                    <FontAwesomeIcon icon="globe" className="icon" />
                    <span className="sim-card-margin" onClick={() => this.abrirModalPromo('roaming')}>{t('Herramientas.verRoaming',)}</span>
                </div>}

                {this.state.servicios && <div className="herramientas-list">
                    <FontAwesomeIcon icon="phone" className="icon" />
                    <span className="sim-card-margin" onClick={() => this.abrirModalServicios()}>{t('Herramientas.verServicios',)}</span>
                </div>}


                <Modal isOpen={this.state.modal} >
                    <ModalHeader className="modal-header-puk"
                        style={{
                            background: this.props.color,
                        }}>
                        <span className="titulo-puk">{t('Herramientas.titulo',)}</span>
                        <div className="modal-cerrar-tarifa">
                            <button className="modal-cerrar-tarifa" onClick={this.cerrarModal}>
                                <FontAwesomeIcon icon="xmark" className="icon-black-tarifas" />
                            </button>
                        </div>
                    </ModalHeader>
                    <ModalBody className="modal-body-puk">
                        <select name="telefono" onChange={this.handleChange} className="select-tlf" >
                            <option key="0" value="" disabled selected>{t('Consumo.numero',)}</option>
                            {this.state.datos.map((dato) =>
                                <option key={dato.id} value={dato}>{dato}</option>
                            )}
                        </select>
                        <div className="sim">
                            <div className="contenedor-sim">
                                <div className="pin1">
                                    <span > Pin</span><span className="puk1-mar"> {this.state.pin1}</span>
                                </div>
                                <div className="pin2">
                                    <span > {t('Herramientas.pin',)}</span><span className="puk1-mar"> {this.state.pin2}</span>
                                </div>
                                <div className="puk1">
                                    <span> {t('Herramientas.puk1',)} </span><span className="puk-mar"> {this.state.puk}</span>
                                </div>
                                <span className="float-right sim-codigo">  {this.state.sim}</span>
                            </div>
                        </div>
                        {this.state.sim2 &&
                            <div className="sim">
                                <div className="contenedor-sim">
                                    <div className="pin1">
                                        <span > Pin</span><span className="puk1-mar"> {this.state.pin1sim}</span>
                                    </div>
                                    <div className="pin2">
                                        <span > {t('Herramientas.pin',)}</span><span className="puk1-mar"> {this.state.pin2sim}</span>
                                    </div>
                                    <div className="puk1">
                                        <span> {t('Herramientas.puk1',)} </span><span className="puk-mar"> {this.state.puksim}</span>
                                    </div>
                                    <span className="float-right sim-codigo">  {this.state.simsim}</span>
                                </div>
                            </div>

                        }
                    </ModalBody>
                    <ModalFooter className="modal-footer-puk">
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.modalPromo} >
                    <ModalHeader className="modal-header-puk"
                        style={{
                            background: this.props.color,
                        }}>
                        <span className="titulo-puk">{t('Herramientas.promotitulo',)}</span>
                        <div className="modal-cerrar-tarifa">
                            <button className="modal-cerrar-tarifa" onClick={() => this.cerrarModalPromo}>
                                <FontAwesomeIcon icon="xmark" className="icon-black-tarifas" />
                            </button>
                        </div>
                    </ModalHeader>
                    <ModalBody className="modal-body-puk">
                        <div>
                            <p className="nombrePromo">{this.state.datosPromoNombre}</p>
                            <p className="codigoPromo">{t('Herramientas.promoCodigo',)} : {this.state.datosPromoCodigo}</p>
                            <img className="imagenPromo"
                                src={imagenPromo}
                                alt="imagenPromo" />

                        </div>
                    </ModalBody>
                    <ModalFooter className="modal-footer-puk">
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.modalServicios} >
                    <ModalHeader className="modal-header-puk"
                        style={{
                            background: this.props.color,
                        }}>
                        <span className="titulo-puk">{t('Herramientas.verServicios',)}</span>
                        <div className="modal-cerrar-tarifa">
                            <button className="modal-cerrar-tarifa" onClick={this.cerrarModalservicios}>
                                <FontAwesomeIcon icon="xmark" className="icon-black-tarifas" />
                            </button>
                        </div>
                    </ModalHeader>
                    <ModalBody className="modal-body-puk">
                        <><Servicios /></>
                    </ModalBody>
                    <ModalFooter className="modal-footer-puk">
                    </ModalFooter>
                </Modal>
                {(process.env.REACT_APP_APP_NAME != 'TUO' && process.env.REACT_APP_APP_NAME != 'TMOVIL') ?
                    <Modal isOpen={this.state.modalRoaming} >
                        <ModalHeader className="modal-header-puk"
                            style={{
                                background: this.props.color,
                            }}>
                            <span className="titulo-puk">{t('Herramientas.verRoaming',)}</span>
                            <div className="modal-cerrar-tarifa">
                                <button className="modal-cerrar-tarifa" onClick={this.cerrarModalRoaming}>
                                    <FontAwesomeIcon icon="xmark" className="icon-black-tarifas" />
                                </button>
                            </div>
                        </ModalHeader>
                        <ModalBody className="modal-body-puk">
                            <><Roaming /></>
                        </ModalBody>
                        <ModalFooter className="modal-footer-puk">
                        </ModalFooter>
                    </Modal>

                    : ''}

            </div >
        );
    };

}
const Herramientas = withTranslation('common')(HerramientasTranslated)
export default Herramientas;